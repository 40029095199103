.heading2 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.32px;
  line-height: 2.687rem;
  font-weight: 600;
}
.cloudStorageIcon,
.withVoiceRecorder {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.withVoiceRecorder {
  width: 37.975rem;
  position: relative;
  font-size: var(--font-size-mini-9);
  letter-spacing: 0.32px;
  line-height: 1.653rem;
  font-weight: 500;
  justify-content: center;
}
.cloudStorageIcon {
  width: 49.125rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.188rem 0;
  flex-shrink: 0;
}
.frameChild {
  width: 26.063rem;
  height: 13.688rem;
  position: relative;
  border-radius: var(--br-mini);
  border: 1px solid var(--color-red-100);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.frameItem,
.step1Click {
  position: relative;
  z-index: 1;
}
.frameItem {
  width: 2.188rem;
  height: 2.188rem;
}
.step1Click {
  letter-spacing: 0.32px;
  line-height: 1.653rem;
  font-weight: 600;
}
.editorOnlineTo,
.uploadOrDrag {
  margin: 0;
}
.uploadOrDragContainer {
  position: relative;
  font-size: var(--font-size-sm-2);
  letter-spacing: 0.25px;
  line-height: 1.24rem;
  text-align: center;
  z-index: 1;
}
.audioRecorderIcon,
.rectangleParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
}
.audioRecorderIcon {
  align-self: stretch;
  gap: 0.688rem 0;
}
.rectangleParent {
  flex: 0.8952;
  border-radius: var(--br-mini);
  border: 1px solid var(--color-red-100);
  box-sizing: border-box;
  padding: var(--padding-25xl) var(--padding-30xl) var(--padding-25xl)
    var(--padding-31xl);
  gap: var(--gap-xl);
  min-width: 19.563rem;
  max-width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}
.compressorFrame {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--color-red-100);
  filter: blur(4px);
  width: 100%;
  height: 100%;
}
.linkFrameIcon {
  position: absolute;
  height: 43.43%;
  width: 54.29%;
  top: 28.57%;
  right: 22.86%;
  bottom: 28%;
  left: 22.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.compressorFrameParent {
  width: 2.188rem;
  height: 2.188rem;
  position: relative;
}
.step2Edit {
  width: 11.375rem;
  position: relative;
  letter-spacing: 0.32px;
  line-height: 1.653rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adjustLightingColor,
.effectsAndCustomize {
  margin: 0;
}
.adjustLightingColorContainer {
  position: relative;
  font-size: var(--font-size-sm-2);
  letter-spacing: 0.25px;
  line-height: 1.24rem;
}
.step2EditRecordingParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem 0;
  flex-shrink: 0;
}
.frameInner,
.frameParent {
  border-radius: var(--br-mini);
  border: 1px solid var(--color-red-100);
  box-sizing: border-box;
  max-width: 100%;
}
.frameInner {
  width: 26.063rem;
  height: 13.688rem;
  position: relative;
  display: none;
}
.frameParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-25xl) var(--padding-12xl);
  gap: var(--gap-xl);
  min-width: 19.563rem;
  z-index: 2;
  margin-bottom: 2rem;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--color-red-100);
  filter: blur(4px);
  width: 100%;
  height: 100%;
}
.shapeIcon {
  position: absolute;
  height: 48.57%;
  width: 40%;
  top: 25.71%;
  right: 28.57%;
  bottom: 25.71%;
  left: 31.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.rectangleGroup {
  width: 2.188rem;
  height: 2.188rem;
  position: relative;
}
.step3Download {
  position: relative;
  letter-spacing: 0.32px;
  line-height: 1.653rem;
  font-weight: 600;
}
.downloadYourEdited,
.pngOrPdf {
  margin: 0;
}
.downloadYourEditedContainer {
  position: relative;
  font-size: var(--font-size-sm-2);
  letter-spacing: 0.25px;
  line-height: 1.24rem;
}
.step3DownloadShareParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.688rem 0;
  flex-shrink: 0;
}
.frameChild1 {
  width: 26.063rem;
  height: 13.688rem;
  position: relative;
  border-radius: var(--br-mini);
  border: 1px solid var(--color-red-100);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.compressVideoButton,
.frameGroup,
.section {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frameGroup {
  flex: 1;
  border-radius: var(--br-mini);
  border: 1px solid var(--color-red-100);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-25xl) var(--padding-12xl);
  gap: var(--gap-xl);
  min-width: 19.563rem;
  z-index: 2;
}
.compressVideoButton,
.section {
  align-self: stretch;
}
.compressVideoButton {
  flex-direction: row;
  align-items: flex-start;
  gap: 0 1rem;
  flex-shrink: 0;
  font-size: var(--font-size-mini-9);
}
.section {
  background-color: var(--color-lavenderblush);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-31xl) var(--padding-60xl) var(--padding-31xl)
    var(--padding-59xl);
  box-sizing: border-box;
  gap: 2.563rem 0;
  text-align: center;
  font-size: var(--font-size-10xl-8);
  color: var(--color-gray-100);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1300px) {
  .compressVideoButton {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section {
    gap: 2.563rem 0;
    padding-left: var(--padding-20xl);
    padding-right: var(--padding-20xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading2 {
    font-size: var(--font-size-5xl);
    line-height: 2.125rem;
  }
  .rectangleParent {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
  }
  .section {
    padding-top: var(--padding-13xl);
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
}
