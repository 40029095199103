@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.heading2,
.heading2HowToEditPhotosWrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.heading2 {
  width: 49.1rem;
  position: relative;
  letter-spacing: 0.32px;
  line-height: 2.688rem;
  font-weight: 600;
  align-items: center;
  flex-shrink: 0;
  opacity: 0;
}
.heading2.animate {
  animation: 1s ease 0s 1 normal forwards fade-in;
}
.heading2HowToEditPhotosWrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
}
.frameChild {
  width: 15.75rem;
  height: 29.25rem;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  display: none;
}
.frameItem {
  align-self: stretch;
  height: 11.625rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 1;
}
.meetingOrLecture {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
  z-index: 1;
}
.meetingOrLectureRecordingWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-sm) 0 var(--padding-mini);
}
.captureImportantDiscussions {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  color: var(--color-gray-100);
  z-index: 1;
}
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frameWrapper,
.rectangleParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 var(--padding-5xs);
}
.rectangleParent {
  width: 15.75rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-5xs) var(--padding-33xl)
    var(--padding-4xs);
  box-sizing: border-box;
  gap: 2.5rem;
}
.frameInner,
.groupIcon {
  align-self: stretch;
  position: relative;
}
.frameInner {
  height: 29.25rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  display: none;
}
.groupIcon {
  height: 7.975rem;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.interviewsAndOral {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
  z-index: 1;
}
.interviewsAndOralHistoryWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-sm) 0 var(--padding-mini);
}
.conductInterviewsOr {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  color: var(--color-gray-100);
  z-index: 1;
}
.frameContainer,
.frameDiv {
  display: flex;
  justify-content: flex-start;
}
.frameDiv {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-xl);
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1.063rem 0 1rem;
}
.rectangleDiv,
.rectangleGroup {
  width: 15.75rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
}
.rectangleGroup {
  align-self: stretch;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.063rem 0.063rem var(--padding-43xl);
  box-sizing: border-box;
  gap: 4.588rem;
}
.rectangleDiv {
  height: 29.25rem;
  position: relative;
  display: none;
}
.icon {
  align-self: stretch;
  height: 10.125rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}
.languageLearningAnd {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
  z-index: 1;
}
.languageLearningAndPronunciWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
}
.improveSpeakingAnd {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  color: var(--color-gray-100);
  z-index: 1;
}
.frameParent1,
.frameWrapper1 {
  display: flex;
  align-items: flex-start;
}
.frameParent1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameWrapper1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-3xs) 0 0.688rem;
}
.frameChild1,
.rectangleContainer {
  width: 15.75rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
}
.rectangleContainer {
  align-self: stretch;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 2.875rem var(--padding-8xs) var(--padding-43xl) 0.25rem;
  box-sizing: border-box;
  gap: 3.625rem;
}
.frameChild1 {
  height: 29.25rem;
  position: relative;
  display: none;
}
.groupIcon1 {
  align-self: stretch;
  height: 11.75rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.voiceMemosAnd {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
  z-index: 1;
}
.voiceMemosAndPersonalRefleWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
}
.useAsA {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  color: var(--color-gray-100);
  z-index: 1;
}
.frameParent2,
.frameWrapper2 {
  display: flex;
  align-items: flex-start;
}
.frameParent2 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frameWrapper2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-4xs) 0 var(--padding-3xs);
}
.frameParent,
.groupDiv,
.section {
  display: flex;
  justify-content: flex-start;
}
.groupDiv {
  align-self: stretch;
  width: 15.75rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.938rem 0.375rem var(--padding-33xl) var(--padding-8xs);
  box-sizing: border-box;
  gap: 2.938rem;
}
.frameParent,
.section {
  align-items: flex-start;
}
.frameParent {
  width: 81.25rem;
  height: 29.25rem;
  overflow-x: auto;
  flex-shrink: 0;
  flex-direction: row;
  gap: 0.625rem;
  max-width: 100%;
  font-size: var(--font-size-base);
  color: var(--color-black);
}
.section {
  width: 100%;
  position: relative;
  background-color: #fff1f1;
  overflow: hidden;
  flex-direction: column;
  padding: 5rem 4.375rem var(--padding-43xl);
  box-sizing: border-box;
  gap: 4.313rem;
  letter-spacing: normal;
  text-align: center;
  font-size: 1.863rem;
  color: #2b2b2b;
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1200px) {
  .frameParent {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 725px) {
  .frameParent {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .section {
    gap: 2.125rem 4.313rem;
    padding-left: var(--padding-16xl);
    padding-right: var(--padding-16xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 650px) {
  .rectangleParent {
    padding-top: 1.625rem;
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
  .rectangleContainer,
  .rectangleGroup {
    padding-top: 2.625rem;
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
  .rectangleContainer {
    padding-top: 1.875rem;
  }
  .groupDiv {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading2 {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
  .section {
    gap: 1.063rem 4.313rem;
  }
}
