.audioRecorder {
  height: 1.175rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.477rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.toolSbCutAudiosvgIcon,
.toolSbCutVideosvgIcon,
.toolSbMuteVideosvgIcon,
.toolSbReverseVideosvgIcon {
  position: absolute;
  top: 0.019rem;
  left: 0.019rem;
  width: 1.288rem;
  height: 1.288rem;
  overflow: hidden;
}
.toolSbCutAudiosvgIcon,
.toolSbMuteVideosvgIcon,
.toolSbReverseVideosvgIcon {
  top: 1.675rem;
}
.toolSbCutAudiosvgIcon,
.toolSbMuteVideosvgIcon {
  top: 3.338rem;
  left: 0.013rem;
}
.toolSbCutAudiosvgIcon {
  top: 5rem;
  left: 0.019rem;
}
.videoEditorTools {
  height: 6.25rem;
  width: 1.313rem;
  position: relative;
}
.cutAudio {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.reverseAudio1 {
  height: 0.875rem;
  flex-shrink: 0;
}
.cutAudio1,
.muteAudio,
.reverseAudio1 {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
}
.muteAudio {
  width: 4.938rem;
}
.cutAudio1 {
  height: 0.875rem;
  flex-shrink: 0;
}
.reverseAudio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.75rem 0;
}
.audioManipulationSet {
  margin-top: -0.062rem;
  margin-bottom: -0.062rem;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 var(--padding-mid);
  gap: 0 0.25rem;
  font-size: var(--font-size-xs);
}
.audioManipulationSet,
.divmuigridRoot,
.mergeAudio {
  display: flex;
  align-items: center;
}
.divmuigridRoot {
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-sm) 0 0;
  gap: 0.625rem 0;
}
.mergeAudio {
  height: 1.188rem;
  position: relative;
  font-size: var(--font-size-base-8);
  letter-spacing: 0.11px;
  line-height: 1.477rem;
  flex-shrink: 0;
}
.toolSbRotateVideosvgFillIcon {
  height: 1.231rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.rotateAudio {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.videoImposeAudio {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 0.188rem;
}
.toolSbCropVideosvgFillIcon {
  height: 1.231rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.cropAudio {
  height: 0.875rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
}
.videoImposeAudio1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 0.188rem;
}
.toolSbVideoImposeAudiosvgIcon {
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.addMusicTo,
.flipVideo {
  display: flex;
  align-items: center;
}
.addMusicTo {
  height: 0.894rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.flipVideo {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 0.188rem;
}
.toolSbMergeAudiosvgFillIcon {
  height: 1.344rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.mergeAudio1 {
  height: 0.894rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
}
.mergeAudios,
.qRCodeCreator {
  height: 1.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 0 0.188rem;
}
.mergeAudios {
  height: 7.875rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-2xs) 0 0;
  gap: 0.438rem 0;
  font-size: var(--font-size-xs);
}
.resizeVideo1 {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.175rem;
}
.resizeVideo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs);
  font-size: var(--font-size-base-8);
}
.toolSbVideoMakersvgIcon {
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.videoMaker,
.videoMaker1 {
  display: flex;
  align-items: center;
}
.videoMaker1 {
  height: 0.894rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.videoMaker {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  gap: 0 0.125rem;
}
.toolSbFlipVideosvgIcon {
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.flipVideo1,
.videoMaker2 {
  display: flex;
  align-items: center;
}
.flipVideo1 {
  height: 0.894rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.videoMaker2 {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  gap: 0 0.125rem;
}
.toolSbAdjustVideosvgIcon {
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.adjustVideo,
.videoMaker3 {
  display: flex;
  align-items: center;
}
.adjustVideo {
  height: 0.894rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.videoMaker3 {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  gap: 0 0.125rem;
}
.scanISBN,
.scanWifiAndConnect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.scanWifiAndConnect {
  gap: 0.375rem 0;
}
.scanISBN {
  height: 7.938rem;
  padding: 0 var(--padding-xs) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-xs);
}
.videoCompressor {
  height: 1.175rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.477rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.movToMp4,
.mp4Converter,
.speedVideo,
.videoConverter {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.mPConverterFrame {
  gap: 0.375rem 0;
  font-size: var(--font-size-xs);
}
.mPConverterFrame,
.videoMakerFrame,
.videoMakerFrameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.videoMakerFrame {
  gap: 0.625rem 0;
}
.videoMakerFrameWrapper {
  height: 7.938rem;
  padding: 0 var(--padding-xs) 0 0;
  box-sizing: border-box;
}
.qrCodeCreator {
  height: 1.175rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.477rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.scanLocationAnd,
.scanProductsScan,
.scanUrlscanWebsite,
.scanWifiAnd {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
}
.scanUrlscanWebsiteParent {
  gap: 0.375rem 0;
  font-size: var(--font-size-xs);
}
.qrCodeCreatorParent,
.scanUrlscanWebsiteParent,
.soundCaptureSymbolInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.qrCodeCreatorParent {
  gap: 0.625rem 0;
}
.soundCaptureSymbolInner {
  padding: 0 0 var(--padding-4xs);
}
.soundCaptureSymbol {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 4.5rem;
  max-width: 100%;
}
.contactInfo {
  height: 1.175rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.477rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.mailsvgFillIcon,
.phonesvgIcon {
  position: absolute;
  top: 0.019rem;
  left: -0.031rem;
  width: 1.288rem;
  height: 0.813rem;
  overflow: hidden;
}
.phonesvgIcon {
  top: 1.519rem;
  height: 1.288rem;
}
.mailsvgFillParent {
  width: 1.313rem;
  height: 2.813rem;
  position: relative;
}
.mailFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.contactraysaarcom,
.termsOfUse {
  position: relative;
  letter-spacing: 0.11px;
  line-height: 1.125rem;
  white-space: nowrap;
}
.termsOfUse {
  height: 0.894rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.contactraysaarcomFrame {
  flex-direction: column;
  gap: 0.5rem 0;
}
.contactInfoParent,
.contactraysaarcomFrame,
.emailPhoneFrame,
.fRAMEFrameInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailPhoneFrame {
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 0.188rem;
  font-size: var(--font-size-xs);
}
.contactInfoParent,
.fRAMEFrameInner {
  flex-direction: column;
}
.contactInfoParent {
  gap: 0.375rem 0;
}
.fRAMEFrameInner {
  padding: var(--padding-sm) 0 0;
}
.fRAMEFrame,
.separator {
  align-self: stretch;
  box-sizing: border-box;
}
.fRAMEFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  max-width: 100%;
  gap: var(--gap-xl);
}
.separator {
  height: 0.094rem;
  position: relative;
  background-color: var(--color-gray-500);
  border-bottom: 0.8px solid var(--color-gray-400);
  overflow: hidden;
  flex-shrink: 0;
}
.audioRecorderAll {
  font-weight: 300;
}
.audioRecorderAllContainer {
  height: 0.75rem;
  width: 13.788rem;
  position: relative;
  letter-spacing: 0.11px;
  line-height: 0.939rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.aboutUs,
.privacyPolicy,
.termsOfUse1 {
  letter-spacing: 0.1px;
  line-height: 0.984rem;
  font-weight: 300;
}
.aboutUs {
  position: relative;
}
.privacyPolicy,
.termsOfUse1 {
  flex: 1;
  display: inline-block;
}
.termsOfUse1 {
  position: relative;
  min-width: 2.563rem;
}
.privacyPolicy {
  min-width: 2.75rem;
}
.help,
.privacyPolicy,
.svgIcon {
  position: relative;
}
.help {
  letter-spacing: 0.1px;
  line-height: 0.984rem;
  font-weight: 300;
}
.svgIcon {
  height: 0.75rem;
  width: 1.125rem;
  overflow: hidden;
  flex-shrink: 0;
}
.english {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 0.984rem;
  font-weight: 300;
  z-index: 1;
}
.termsLink {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutUsLink,
.separatorLine {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.aboutUsLink {
  width: 20.063rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 0;
  gap: 0 1.063rem;
}
.separatorLine {
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-xl);
}
.footer,
.footerMedia,
.frameMain {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameMain {
  flex: 1;
  flex-direction: column;
  gap: 0.688rem 0;
}
.footer,
.footerMedia {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-2xs);
  box-sizing: border-box;
  font-size: var(--font-size-2xs-5);
  font-family: var(--font-roboto);
}
.footer {
  background-color: var(--color-crimson);
  box-shadow: 0 1.5px 0.75px -0.75px rgba(0, 0, 0, 0.2),
    0 0.8px 0.75px rgba(0, 0, 0, 0.14), 0 0.8px 2.25px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-base) var(--padding-55xl) var(--padding-lgi)
    var(--padding-43xl);
  gap: 2.313rem 0;
  text-align: left;
  font-size: var(--font-size-base-8);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1300px) {
  .fRAMEFrame {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1125px) {
  .soundCaptureSymbol {
    flex-wrap: wrap;
    gap: 0 4.5rem;
  }
}
@media screen and (max-width: 800px) {
  .soundCaptureSymbol {
    gap: 0 4.5rem;
  }
  .separatorLine {
    flex-wrap: wrap;
  }
  .footer {
    gap: 2.313rem 0;
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .aboutUsLink {
    flex-wrap: wrap;
  }
}
