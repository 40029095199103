@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-rubik: Rubik;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-2xs-5: 0.656rem;
  --font-size-xs: 0.75rem;
  --font-size-base-8: 0.988rem;
  --font-size-2xl-2: 1.325rem;
  --font-size-mid: 1.063rem;
  --font-size-6xl-5: 1.594rem;
  --font-size-xl: 1.25rem;
  --font-size-base: 1rem;
  --font-size-5xl: 1.5rem;
  --font-size-lgi: 1.188rem;
  --font-size-10xl-8: 1.863rem;
  --font-size-sm-2: 0.825rem;
  --font-size-mini-9: 0.931rem;
  --font-size-45xl: 4rem;
  --font-size-19xl: 2.375rem;
  --font-size-32xl: 3.188rem;

  /* Colors */
  --color-white: #fff;
  --color-crimson: #ff363b;
  --color-gray-100: #2b2b2b;
  --color-gray-200: #1a1a1a;
  --color-gray-300: rgba(0, 0, 0, 0.6);
  --color-gray-400: rgba(0, 0, 0, 0.12);
  --color-gray-500: rgba(255, 255, 255, 0.25);
  --color-black: #000;
  --color-royalblue: #727cf5;
  --color-lavenderblush: #fff1f1;
  --color-red-100: #fc040b;
  --color-red-200: #fb0007;

  /* Gaps */
  --gap-61xl: 5rem;
  --gap-18xl: 2.313rem;
  --gap-2xs: 0.688rem;
  --gap-xl: 1.25rem;
  --gap-0: 0rem;
  --gap-7xs: 0.375rem;
  --gap-5xs: 0.5rem;
  --gap-3xs: 0.625rem;
  --gap-6xs: 0.438rem;
  --gap-xs: 0.75rem;
  --gap-43xl: 3.875rem;
  --gap-14xl: 2.063rem;
  --gap-7xl: 1.625rem;
  --gap-12xl: 1.938rem;
  --gap-11xl: 1.875rem;
  --gap-97xl: 7.25rem;
  --gap-116xl: 8.438rem;
  --gap-22xl: 2.563rem;
  --gap-lgi: 1.188rem;
  --gap-71xl: 5.625rem;

  /* Paddings */
  --padding-base: 1rem;
  --padding-55xl: 4.625rem;
  --padding-lgi: 1.188rem;
  --padding-43xl: 3.875rem;
  --padding-12xl: 1.938rem;
  --padding-18xl: 2.313rem;
  --padding-2xs: 0.688rem;
  --padding-12xs: 0.063rem;
  --padding-10xs: 0.188rem;
  --padding-sm: 0.875rem;
  --padding-9xs: 0.25rem;
  --padding-4xs: 0.563rem;
  --padding-xs: 0.75rem;
  --padding-11xs: 0.125rem;
  --padding-lg: 1.125rem;
  --padding-mid: 1.063rem;
  --padding-xl: 1.25rem;
  --padding-7xs: 0.375rem;
  --padding-8xs: 0.313rem;
  --padding-20xl: 2.438rem;
  --padding-13xl: 2rem;
  --padding-6xs: 0.438rem;
  --padding-5xs: 0.5rem;
  --padding-41xl: 3.75rem;
  --padding-11xl: 1.875rem;
  --padding-31xl: 3.125rem;
  --padding-60xl: 4.938rem;
  --padding-59xl: 4.875rem;
  --padding-25xl: 2.75rem;
  --padding-30xl: 3.063rem;
  --padding-24xl: 2.688rem;

  /* Border radiuses */
  --br-7xs-5: 5.5px;
  --br-3xs: 10px;
  --br-mini: 15px;
  --br-8xs: 5px;
  --br-363xl-2: 382.2px;
}
