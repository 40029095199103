.frameChild {
  width: 15.75rem;
  height: 29.25rem;
  position: relative;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  display: none;
}
.backgroundCompleteIcon {
  position: absolute;
  width: calc(100% - 23.4px);
  top: 0.313rem;
  right: 0.744rem;
  left: 0.719rem;
  max-width: 100%;
  overflow: hidden;
  height: 12.088rem;
}
.character2Icon,
.wallIcon {
  position: absolute;
  top: 10.05rem;
  left: 0.188rem;
  width: 13.625rem;
  height: 0.013rem;
  z-index: 1;
}
.character2Icon {
  top: 5.581rem;
  left: 10.113rem;
  width: 3.256rem;
  height: 7.244rem;
  z-index: 2;
}
.character1Icon {
  position: absolute;
  top: 5.063rem;
  left: 0;
  width: 4.581rem;
  height: 8.238rem;
  z-index: 2;
}
.plantIcon {
  position: absolute;
  top: 0;
  left: 2.113rem;
  width: 1.613rem;
  height: 4.544rem;
  z-index: 1;
}
.groupIcon,
.groupIcon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon1 {
  height: 12.38%;
  width: 17.01%;
  top: 13.59%;
  right: 40.38%;
  bottom: 74.02%;
  left: 42.6%;
}
.groupIcon2 {
  position: absolute;
  top: 0.056rem;
  left: 3.725rem;
  width: 0.394rem;
  height: 0.219rem;
}
.groupIcon3 {
  position: absolute;
  top: 0;
  left: 0.113rem;
  width: 0.181rem;
  height: 0.281rem;
}
.groupIcon4,
.groupIcon5 {
  position: absolute;
  height: 17.58%;
  width: 78.7%;
  top: 42.92%;
  right: 10.36%;
  bottom: 39.5%;
  left: 10.95%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon5 {
  height: 2.49%;
  width: 65.53%;
  top: 34.38%;
  right: 17.16%;
  bottom: 63.13%;
  left: 17.31%;
}
.groupIcon6 {
  height: 5.34%;
  width: 79.29%;
  top: 94.02%;
  right: 0;
  bottom: 0.64%;
  left: 20.71%;
}
.groupIcon6,
.groupIcon7,
.groupIcon8 {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon7 {
  height: 7.19%;
  width: 14.94%;
  top: 92.81%;
  right: 85.06%;
  bottom: 0;
  left: 0;
}
.groupIcon8 {
  top: 65.34%;
  bottom: 34.45%;
}
.groupIcon10,
.groupIcon8,
.groupIcon9 {
  height: 0.21%;
  width: 98.82%;
  right: 1.18%;
  left: 0;
}
.groupIcon9 {
  position: absolute;
  top: 78.01%;
  bottom: 21.78%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon10 {
  top: 90.68%;
  bottom: 9.11%;
}
.groupIcon10,
.groupIcon11,
.groupIcon12,
.groupIcon13 {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon11 {
  height: 0.64%;
  width: 27.96%;
  top: 95.52%;
  right: 51.48%;
  bottom: 3.84%;
  left: 20.56%;
  z-index: 1;
}
.groupIcon12,
.groupIcon13 {
  width: 100%;
  right: 0;
  left: 0;
}
.groupIcon12 {
  height: 16.51%;
  top: 80.5%;
  bottom: 2.99%;
  z-index: 2;
}
.groupIcon13 {
  height: 7.19%;
  top: 68.26%;
  bottom: 24.56%;
}
.groupIcon14 {
  position: absolute;
  height: 2.85%;
  width: 7.4%;
  top: 82.49%;
  right: 88.61%;
  bottom: 14.66%;
  left: 3.99%;
  z-index: 3;
}
.groupIcon14,
.groupIcon15,
.groupIcon16 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcon15 {
  position: absolute;
  height: 2.85%;
  width: 7.4%;
  top: 94.73%;
  right: 88.61%;
  bottom: 2.42%;
  left: 3.99%;
  z-index: 3;
}
.groupIcon16 {
  height: 2.28%;
  width: 25.89%;
  top: 1.14%;
  right: 36.09%;
  bottom: 96.58%;
  left: 38.02%;
}
.group,
.group1,
.groupIcon16 {
  position: absolute;
}
.group1 {
  height: 81.07%;
  width: calc(100% - 17.4px);
  top: 10.1%;
  right: 0.544rem;
  bottom: 8.83%;
  left: 0.544rem;
  z-index: 1;
}
.group {
  top: 1.969rem;
  left: 4.656rem;
  width: 5.313rem;
  height: 10.831rem;
  z-index: 2;
}
.backgroundCompleteParent {
  height: 13.3rem;
  flex: 1;
  position: relative;
}
.frameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}
.podcastingAndContent {
  flex: 1;
  position: relative;
  line-height: 1.25rem;
  font-weight: 500;
  z-index: 1;
}
.podcastingAndContentCreatioWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-5xs);
}
.producePodcastsAudio {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  color: var(--color-gray-100);
  z-index: 1;
}
.rectangleParent {
  width: 15.75rem;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-2xl) var(--padding-mini) var(--padding-33xl);
  box-sizing: border-box;
  gap: 1.944rem;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 650px) {
  .rectangleParent {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
