.playback1 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-13xl);
}
.playback,
.thereAreMany {
  position: relative;
  letter-spacing: 0.32px;
}
.playback {
  margin: 0;
  font-size: inherit;
  line-height: 2.687rem;
  font-weight: 600;
  font-family: inherit;
  background: linear-gradient(89.94deg, #000, #fd050c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.thereAreMany {
  width: 32.375rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.subheader {
  flex-direction: row;
  padding: 0 0 0 var(--padding-8xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-base);
}
.frameGroup,
.header,
.subheader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameGroup {
  flex-direction: column;
  gap: 1.875rem 0;
}
.header {
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 450px) {
  .playback {
    font-size: var(--font-size-lgi);
    line-height: 2.125rem;
  }
}
