.recorder {
  color: var(--color-red-200);
}
.voiceRecorder,
.welcomeToVoice {
  position: relative;
  display: flex;
  align-items: center;
}
.voiceRecorder {
  margin: 0;
  height: 2.813rem;
  font-size: inherit;
  line-height: 5.625rem;
  font-weight: 600;
  font-family: inherit;
  max-width: 100%;
}
.welcomeToVoice {
  font-size: var(--font-size-base);
  line-height: 1.25rem;
  color: var(--color-gray-300);
  max-width: 32.375rem; /* Set a max-width for desktop */
  width: 100%; /* Take full width on smaller screens */
  margin: 0 auto; /* Center the content horizontally */
}
.voiceRecorderParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.875rem 0;
  max-width: 100%;
}
.separatorChild {
  width: 4.5rem;
  height: 3.5rem;
  position: relative;
  cursor: pointer;
  border: 1px solid black;
  
}
.pressMicTo {
  /* margin-bottom: 1rem; */
  /* height: 0.875rem; */
  position: relative;
  font-size: inherit;
  line-height: 1.25rem;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.separator {
  flex-direction: column;
  align-items: center;
  gap:0;
}
.contactInfo,
.separator,
.videoMakerFlip {
  display: flex;
  justify-content: flex-start;
  /* border: 1px solid red; */
}
.contactInfo {
  width: 17.5rem;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--font-size-xl);
  color: var(--color-red-200);
}
.videoMakerFlip {
  flex-direction: column;
  align-items: center;
  gap: 2.5rem 0;
  min-width: 32.375rem;
  max-width: 100%;
  
}
.mPConverterChild {
  align-self: stretch;
  height: 23.25rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.mPConverter,
.videoMakerFlipParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.mPConverter {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-sm) 0 0;
  min-width: 29.875rem;
  
}
.videoMakerFlipParent {
  width: 82.5rem;
  flex-direction: row;
  padding: 0 var(--padding-xl) var(--padding-6xs);
  gap: 0 1.625rem;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-45xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1125px) {
  .videoMakerFlip {
    flex: 1;
  }
  .videoMakerFlipParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .voiceRecorder {
    font-size: var(--font-size-32xl);
    line-height: 4.5rem;
    width: 100%;
    overflow: hidden;
  }
  .videoMakerFlip {
    gap: 5.625rem 0;
    min-width: 100%;
  }
  .mPConverter {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .voiceRecorder {
    font-size: var(--font-size-19xl);
    line-height: 3.375rem;
  }
  .pressMicTo {
    font-size: var(--font-size-base);
    line-height: 1rem;
  }
  .videoMakerFlip {
    gap: 5.625rem 0;
  }
}
/* FrameComponent.module.css */


