.emptyFrameIcon {
    width: calc(100% - 1px);
    height: 100%;
    position: absolute;
    margin: 0 !important;
    top: -1rem;
    right: 0.063rem;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  
  .recording {
    margin: 0;
    
    position: relative;
    font-size: inherit;
    line-height: 2rem;
    font-weight: 600;
    font-family: inherit;
    background: linear-gradient(90deg, #fb0007, #3c0002);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    top:-5rem;
  }
  .recordingText {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0.938rem;
  }
  .childFrameChild {
    height: 17.375rem;
    width: 80rem;
    position: relative;
    border-radius: var(--br-8xs);
    background-color: var(--color-white);
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    display: none;
    max-width: 100%;
    
    
  }
  .childFrameItem {
    height: 10rem;
    flex: 1;
    position: relative;
    max-width: 70%;
    overflow: hidden;
    z-index: 2;
    
    
  }
  .childFrame {
    align-self: stretch;
    border-radius: var(--br-8xs);
    background-color: var(--color-white);
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: row;
    justify-content: center;
    padding: 0.25rem 0.375rem 0.188rem 0;
    box-sizing: border-box;
    max-width: 70%;
    z-index: 1;
    margin-top:-7rem;
    margin-left: 10rem;
  }
  .childFrame,
  .innerFrame,
  .placeholderText {
    display: flex;
    align-items: center;
    
  }
  .placeholderText {
    height: 0.688rem;
    position: relative;
    line-height: 1.25rem;
    font-weight: 500;
    justify-content: center;
    white-space: nowrap;
    z-index: 1;
  }
  .innerFrame {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2.375rem 0;
    max-width: 100%;
    font-size: 1rem;
    /* overflow-x: hidden;
    overflow-y: auto; */
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    
  }
  .frameChild {
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
    min-height: 2.5rem;
    cursor: pointer;
    z-index: 1;
    top:-2rem;
    left:-1.2rem;
  }
  .frameChilds {
    height: -1.5rem;
    width: 2.5rem;
    position: relative;
    min-height: 2.5rem;
    cursor: pointer;
    z-index: 1;
    top:-4rem;
    left:1rem;
  }
  .frameItem {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-red);
    width: 100%;
    height: 100%;
    display: none;
  }
  .childVectorIcon {
    position: absolute;
    height: 50%;
    width: 50%;
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 1;
  }
  .rectangleParent {
    /* height: 2.5rem;
    width: 2.5rem; */
    position: relative;
    /* border-radius: var(--br-11xl);
    background-color: var(--color-red); */
    cursor: pointer;
    z-index: 1;
    top:-1.5rem;
  }
  .frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-xl);
  }
  
  .pause{
    position: relative;
    letter-spacing: -0.02em;
    line-height: 100%;
    font-weight: 500;
    z-index: 1;
    top:-1.8rem;
    left:-1rem;
  }
  .stop
  
   {
    position: relative;
    letter-spacing: -0.02em;
    line-height: 100%;
    font-weight: 500;
    z-index: 1;
    top:-1.6rem;
  }
  .stopText{
    position:relative;
    bottom:2.9rem;
    left:1rem;
  }
  .Play{
    position: relative;
    letter-spacing: -0.01em;
    font-weight: 500;
    z-index: 1;
    top:-2rem;
    left:-1rem;
  }
  .pauseFrame,
  .vectorGroup {
    display: flex;
    align-items: flex-start;
  }
  .pauseFrame {
    width: 6.375rem;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--gap-xl);
  }
  .vectorGroup {
    width: 6.625rem;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem 0;
    font-size: var(--font-size-sm);
    font-family: var(--font-inter);
  }
  .emptyFrameParent,
  .voiceWebsite {
    position: relative;
    display: flex;
    justify-content: flex-start;
  }
  .emptyFrameParent {
    width: 96.063rem;
    flex-direction: column;
    align-items: center;
    padding: 8.188rem 11rem 4rem 5rem;
    box-sizing: border-box;
    gap: 3.688rem 0;
    max-width: 107%;
    flex-shrink: 0;
    text-align: center;
    font-size: 1.75rem;
    color: var(--color-black);
    font-family: var(--font-poppins);
  }
  .voiceWebsite {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    overflow: hidden;
    flex-direction: row;
    align-items: flex-start;
    letter-spacing: normal;
  }
  @media screen and (max-width: 1200px) {
    .emptyFrameParent {
      padding-left: 2.5rem;
      padding-right: 5.5rem;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 768px) {
    .innerFrame {
      gap: 1.188rem 0;
      padding-bottom: 1.5rem;
      padding-right: 2rem;
      margin-top: 1.5rem;
    }
    
    .emptyFrameParent {
      gap: 1.813rem 0;
      padding-top: 5.313rem;
      padding-right: 2.75rem;
      padding-bottom: 2.625rem;
      box-sizing: border-box;
    }
    .childFrame {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 5rem;
      max-width: 75%;
    }
  }
  @media screen and (max-width: 480px) {
    .recording {
      font-size: 1.375rem;
      line-height: 1.5rem;
    }
    .emptyFrameParent {
      padding-right: 1.25rem;
      box-sizing: border-box;
    }
    .childFrame {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0rem;
      max-width: 95%;
    }

  }
  

  /* .voiceWebsite {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    overflow: hidden;
    flex-direction: row;
    align-items: flex-start;
    letter-spacing: normal;
  } */

/* new promotional css  */
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #f9f9f9; */
  /* min-height: 100vh; */
  min-width:100%;
  margin: 1rem 0;
}

.containerBox {
  width: 75%;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;
}

.imageBox {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phoneImageContainer {
  filter: drop-shadow(1px 8px 16px rgba(0, 0, 0, 0.6));
}

.phoneImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.contentBox {
  width: 60%;
  padding: 1rem;
  /* border: 1px solid black; */
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
  justify-content: flex-start;
  /* border: 1px solid blue; */
}

.logoContainer {
  display: flex;
  justify-content: flex-start;
  /* border: 1px solid black; */
  align-items: end;
}

.logoImage {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.titleBox {
  display: block;
  /* margin: 1rem; */
  /* border: 1px solid red; */
}

.appTitle {
  color: #ff585c;
  font-size: 1.25rem;
  font-weight: 800;
  text-align: start;
  font-family: inherit;
}

.appTagline {
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  font-family: inherit;
}

.textBox {
  margin-top: 1rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
}

.appLink {
  text-decoration: none;
}

.storeButton {
  background: black;
  min-width: 120px;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.storeIcon {
  height: 30px;
  object-fit: contain;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .containerBox {
    flex-wrap: wrap;
    width: 90%;
    padding: 1rem;
  }

  .imageBox,
  .contentBox {
    width: 100%;
    margin-bottom: 1rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .logoImage {
    height: 60px;
    width: 60px;
  }

  .appTitle {
    font-size: 1.1rem;
  }

  .appTagline {
    font-size: 14px;
    text-align: center;
  }

  .buttonsContainer{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .containerBox {
    width: 100%;
    padding: 0.5rem;
  }

  .header{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phoneImage {
    max-width: 100%;
  }

  .storeButton {
    min-width: 100px;
    padding: 0.5rem 1rem;
  }

  .appTitle {
    font-size: 1rem;
  }

  .appTagline {
    font-size: 12px;
    text-align: center;
  }
  .buttonsContainer{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .containerBox {
    box-shadow: none;
    padding: 0.5rem;
    border-radius: 10px;
  }
  .header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .phoneImage {
    max-width: 100%;
  }

  .logoImage {
    height: 50px;
    width: 50px;
  }

  .appTitle {
    font-size: 0.9rem;
  }

  .appTagline {
    font-size: 10px;
    text-align: center;
  }

  .buttonsContainer{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .storeButton {
    min-width: 80px;
    padding: 0.5rem;
  }
}


