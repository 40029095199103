.ourFeatures {
  position: relative;
  letter-spacing: 0.32px;
  line-height: 2.687rem;
  font-weight: 600;
}
.fRAMEFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-20xl);
}
.fRAMEGroupChild {
  height: 28.625rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
  min-width: 25.375rem;
}
.fRAMEGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 2.938rem;
  box-sizing: border-box;
  gap: 0 8rem;
  max-width: 100%;
}
.videoMakerFlipAdjustFrame {
  width: 82.5rem;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 2.063rem 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-10xl-8);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.headerFrame,
.videoMakerFlipAdjustFrame,
.voiceWebsite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
  
}
.headerFrame {
  align-self: stretch;
  gap: 3.875rem 0;
  max-width: 100%;
  flex-shrink: 0;
}
.voiceWebsite {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  /* padding: 1.5rem 0 0; */
  box-sizing: border-box;
  gap: 5rem 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1300px) {
  .fRAMEGroup {
    gap: 0 8rem;
  }
}
@media screen and (max-width: 1125px) {
  .fRAMEGroup {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .fRAMEGroupChild {
    min-width: 100%;
  }
  .fRAMEGroup {
    gap: 0 8rem;
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
  .videoMakerFlipAdjustFrame {
    gap: 2.063rem 0;
  }
  .headerFrame {
    gap: 3.875rem 0;
  }
  .voiceWebsite {
    gap: 5rem 0;
  }
}
@media screen and (max-width: 450px) {
  .ourFeatures {
    font-size: var(--font-size-5xl);
    line-height: 2.125rem;
  }
  .fRAMEGroup {
    gap: 0 8rem;
  }
  .headerFrame {
    gap: 3.875rem 0;
  }
  .voiceWebsite {
    gap: 5rem 0;
  }
}

