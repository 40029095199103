@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-sm: 0.875rem;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-red: #fb0007;

  /* Gaps */
  --gap-xl: 1.25rem;

  /* Border radiuses */
  --br-11xl: 30px;
  --br-8xs: 5px;
}
