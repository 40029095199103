@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: rgba(0, 0, 0, 0.5);
  --color-black: #000;

  /* Gaps */
  --gap-12xl-1: 1.944rem;
  --gap-11xl: 1.875rem;
  --gap-xl: 1.25rem;

  /* Paddings */
  --padding-43xl: 3.875rem;
  --padding-16xl: 2.188rem;
  --padding-2xl: 1.313rem;
  --padding-mini: 0.938rem;
  --padding-33xl: 3.25rem;
  --padding-xl: 1.25rem;
  --padding-15xl: 2.125rem;
  --padding-5xs: 0.5rem;
  --padding-8xs: 0.313rem;
  --padding-4xs: 0.563rem;
  --padding-3xs: 0.625rem;
  --padding-21xl: 2.5rem;
  --padding-sm: 0.875rem;

  /* Border radiuses */
  --br-mini: 15px;
}
