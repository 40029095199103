.mPConverterLinkChild,
.mPConverterLinkItem {
  align-self: stretch;
  height: 28.625rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.mPConverterLink {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8.438rem 0;
  min-width: 25.375rem;
  max-width: 100%;
}
.videoCompressorChild {
  height: 28.625rem;
  width: 39.063rem;
  position: relative;
  background: linear-gradient(
    130.76deg,
    #ff5a5a,
    #fff 48.36%,
    #fff 57.61%,
    #ff3838
  );
  display: none;
  max-width: 100%;
}
.contactInfoIcon {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0.125rem;
  top: 0.25rem;
  transform: scale(1.072);
}
.wrapperContactInfo {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  width: 13.938rem;
  height: 18.75rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maskGroupIcon {
  position: absolute;
  top: 2.5rem;
  left: 11.5rem;
  width: 13.813rem;
  height: 18.625rem;
  object-fit: contain;
  z-index: 2;
}
.qRCreator {
  height: 21.125rem;
  width: 25.313rem;
  position: relative;
  max-width: 100%;
}
.videoCompressor {
  align-self: stretch;
  background: linear-gradient(
    130.76deg,
    #ff5a5a,
    #fff 48.36%,
    #fff 57.61%,
    #ff3838
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-41xl);
  box-sizing: border-box;
  max-width: 100%;
}
.cropTool,
.rotateTool {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.cropTool {
  align-self: stretch;
  align-items: flex-end;
  gap: 7.25rem 0;
}
.rotateTool {
  flex: 1;
  align-items: flex-start;
  padding: 0 0 var(--padding-20xl);
  box-sizing: border-box;
  min-width: 25.375rem;
}
.resizeVideoButton {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 1.875rem;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 800px) {
  .mPConverterLink {
    gap: 8.438rem 0;
    min-width: 100%;
  }
  .videoCompressor {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  .cropTool {
    gap: 7.25rem 0;
  }
  .rotateTool {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .mPConverterLink {
    gap: 8.438rem 0;
  }
  .videoCompressor {
    padding-top: var(--padding-20xl);
    padding-bottom: var(--padding-20xl);
    box-sizing: border-box;
  }
  .cropTool {
    gap: 7.25rem 0;
  }
}
