.frameChild {
  align-self: stretch;
  height: 28.625rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frameParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: 8.438rem 0;
  min-width: 25.375rem;
  max-width: 100%;
}
.frameInner,
.frameItem {
  align-self: stretch;
  height: 28.625rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7.25rem 0;
  min-width: 25.375rem;
  max-width: 100%;
}
.resizeVideoButton {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 1.875rem;
  max-width: 100%;
}
@media screen and (max-width: 800px) {
  .frameGroup,
  .frameParent {
    gap: 8.438rem 0;
    min-width: 100%;
  }
  .frameGroup {
    gap: 7.25rem 0;
  }
}
@media screen and (max-width: 450px) {
  .frameParent {
    gap: 8.438rem 0;
  }
  .frameGroup {
    gap: 7.25rem 0;
  }
}
