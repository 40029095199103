/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');


html,
body,
#root,
.app{
  height: 100%;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  /* overflow: hidden; */
 
  /* background: linear-gradient(180deg, rgba(114, 124, 245, 0.25) 0%, rgba(114, 124, 245, 0.02) 100%); */
}


.slick-slide > div{
  margin: 0 10px;
}
